import {
  useFieldSelection,
  useLeaseReviewWebViewer,
} from '../components/Review/composables'
import type { ILeaseField } from '@register'

export const useBacklink = () => {
  const { selectedCopiedText, webViewerInstance } = useLeaseReviewWebViewer()
  const { select } = useFieldSelection()

  const onCopyFromDocument = (field: ILeaseField): void => {
    select(field, true)
    webViewerInstance.value?.Core.documentViewer.clearSelection()
  }

  return {
    selectedCopiedText,
    onCopyFromDocument,
  }
}
